.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  padding: 25px 0;
  text-align: center;
  color: #007bff;
  background: none!important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

footer a.nav-link  {
  color: #007bff!important;
}

footer .copyright {
  float: right;
  margin-top: 5px;
}

footer .nav {
  display: inline-block;
  float: left;
}
.footer .nav-item {
  display: inline-block!important;
  text-transform: uppercase;
}


footer .copyright a.nav-link  {
  display:contents;
}

footer a:hover {
  color: #61dafb!important;
}

/* Chrome, Safari, Edge, Opera */
.react-code-input input::-webkit-outer-spin-button,
.react-code-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.react-code-input input[type=number] {
  -moz-appearance: textfield;
}

body {
  background: url("assets/img/background.png") !important;
  background-size: cover !important;
}

.btn-link,
a {
color: #007bff!important;
}

.btn-primary {
background-color: #007bff!important;
border-color: #007bff!important;
}

.btn-outline-primary {
border-color: #007bff!important;
color: #007bff!important;
}

.btn-outline-primary:hover {
background-color: #007bff!important;
color: #fff!important;
}